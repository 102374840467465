<template>
    
    <div>
        <header>
            <div class="section" >
                <h2 class="text-white my0">"학원쏙~"</h2>
                <h2 class="text-white my0">최고의 학원관리 프로그램</h2>
            </div>
        </header>
        
        <section class="home">
            <div class="section row q-col-gutter-lg">
                <div class="col-md-6 text-center">
                    <div class="d-inline-block q-pa-md" style="width:400px">
                        <h2 class="text-left">
                            <span class="text-blue">빠르게 성장하는 학원</span>의 <br/>
                            특징은
                        </h2>
                        <div class="text-left">
                            <p>
                                학원 성장의 핵심은 무엇일까?<br/>
                                첫째, 학부모 설명회<br/>
                                둘째, 수업<br/>
                                셋째, 학원 관리로 요약된다.<br/>
                            </p>
                            <div class="q-mt-md">
                                <q-btn color="primary">무료로 시작하기</q-btn>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="q-pa-md">
                        <img class="w100p" src="@/assets/prg/home.png" />
                    </div>
                </div>
            </div>
        </section>
        
        <section class="class">
            <div class="section row q-col-gutter-lg">
                <div class="col-md-6">
                    <div class="q-pa-md">
                        <video src="@/assets/prg/afterclass.mp4" 
                               muted autoplay playsinline loop
                               style="width:490px;"></video>
                    </div>
                </div>
                <div class="col-md-6 text-center">
                    <div class="d-inline-block q-pa-md" style="width:400px">
                        <h2 class="text-left">
                            <span class="text-blue">관리형 학원</span>의 <br/>
                            학원관리는
                        </h2>
                        <p class="text-left">
                            첫째, 출결관리<br/>
                            둘째, 숙제관리<br/>
                            셋째, 수업후기 리포트이다.<br/><br/>
                            학원은 분명한 교육 가치를 지니고 있어야 하고, 수업한 결과를 매수업후마다, 부모님께 알려드려야 한다.<br/>
                        </p>
                    </div>
                </div>
            </div>
        </section>
        
        <main class="section">
            <section class="text-center">
                <h2>왜 "학원쏙~"인가?</h2>
                <p class="d-line-block text-left" style="width:600px">
                    학원을 경영하는 원장님의 니즈에 가장 잘 충족시키는 솔루션. <br/>
                    학생 관리부터 수업 일정, 수업후 자동 리포트로 부모님과 소통까지 - 모든 것을 한 곳에서 관리하세요. <br/>
                    10년간의 학원관리프로그램 개발 노하우가 담긴 우리의 시스템은 여러분의 학원 운영을 혁신적으로 변화시킬 것입니다.
                </p>
            </section>
            
            <section class="row q-col-gutter-lg features">
                <div class="col-md-4 col-sm-6 ">
                    <div class="feature">
                        <h3><q-icon name="chat" /> 학부모 소통 강화</h3>
                        <p>매 수업마다의 알림리포트를 통하여 자녀의 출석, 성적, 과제 등의 정보를 실시간으로 공유하여 소통을 강화합니다.</p>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 ">
                    <div class="feature">
                        <h3><q-icon name="manage_accounts" /> 종합적인 학생 관리</h3>
                        <p>학생 정보, 출석, 성적, 상담 기록을 체계적으로 관리하여 개별 학생의 발전 과정을 한눈에 쏙~ 파악할 수 있습니다.</p>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 ">
                    <div class="feature">
                        <h3><q-icon name="school" /> 효율적인 수업 관리</h3>
                        <p>수업 일정 관리, 강사 배정, 교실 배정 등을 간편하게 처리할 수 있어 학원 운영의 효율성을 극대화합니다.</p>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 ">
                    <div class="feature">
                        <h3><q-icon name="dns" /> 학원별 보안 db</h3>
                        <p>모든 학원마다 별도의 db를 갖고 있어, 다른 학원의 정보가 원천적으로 분리됩니다. </p>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 ">
                    <div class="feature">
                        <h3><q-icon name="point_of_sale" /> 카드 단말지 지원</h3>
                        <p>카드 결제를 위한 카드 단말기를 추가 비용없이 사용할 수 있습니다. 카드 결제, 현금 영수증을 결제하면 "학원쏙" 프로그램에 자동으로 입력됩니다.</p>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 ">
                    <div class="feature">
                        <h3><q-icon name="smartphone" /> 모바일 지원</h3>
                        <p>언제 어디서나 모바일로 접속하여 중요한 정보를 확인하고 관리할 수 있습니다.</p>
                    </div>
                </div>
                
            </section>
            
            <section class="cta">
                <h2>"학원쏙~"로 학원 관리의 혁신을 경험하세요!</h2>
                <q-btn color="primary" >무료로 시작하기</q-btn>
            </section>
        </main>
        
        <footer>
            <div class="section">
                <p>&copy; 2024 "학원쏙~". 10년의 경험으로 만든 최고의 학원관리 솔루션.</p>
            </div>
        </footer>
    </div>
</template>

<script>
export default {
    components : {
    },
    data : function() {
        return {
        }
    },
    computed :{
    },
    methods : {
    },
    beforeMount() {
    }
}
</script>


<style>
    body {
        line-height: 1.6;
        color: #333;
        margin: 0;
        padding: 0;
    }
    .section {
        max-width: 1200px;
        margin: 0 auto;
        padding: 50px;
    }
    header {
        background-color: #4a90e2;
        color: white;
        text-align: center;
        padding: 2em;
    }
    h1, h2 {
        color: #4a90e2;
        line-height: 1.3;
    }
    h2 {
        font-size: 2.5em;
        margin-bottom: 20px;
    }
    h3 {
        font-size: 2em;
    }
    p {
        font-size: 1.2em;
        max-width: 800px;
        margin: 0 auto;
    }
    .features {
        margin-top: 40px;
    }
    .feature {
        margin-bottom: 30px;
        padding: 20px 20px 40px;
        background-color: #f0f8ff;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0,0,0,0.1);
        transition: transform 0.3s ease;
    }
    .feature:hover {
        transform: translateY(-5px);
    }
    .cta {
        text-align: center;
        margin-top: 50px;
        padding: 50px 0;
        background-color: #f0f8ff;
    }
    
    footer {
        background-color: #333;
        color: white;
        text-align: center;
        padding: 20px 0;
        margin-top: 50px;
    }
    .class {
        background-color: #2c3e50;
        color: white;
        text-align: center;
    }
    .class h2 {
        color: white;
    }
    .class p {
        font-size: 1.2em;
        max-width: 800px;
        margin: 0 auto;
    }
    
</style>